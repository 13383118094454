export function useFormFieldRefs({ formName }) {
  const fieldsRef = React.useRef({})

  const setRef = React.useCallback(
    (fieldName) => {
      const name = fieldName.replace(`${formName}.`, '')

      if (!fieldsRef.current[name]) {
        fieldsRef.current[name] = React.createRef()
      }

      return fieldsRef.current[name]
    },
    [formName]
  )

  return { fields: fieldsRef.current, setRef }
}
