export function moveFocusToError({ refs, error }) {
  const errorKey = Object
    .keys(error.children)
    .find(field => error.children[field].children
      ? error.children[field].self
      : error.children[field]
    )

  if (errorKey && typeof refs[errorKey]?.current?.focus === 'function') {
    refs[errorKey].current.focus()
  }
}
