import { determineLinkProps } from '/machinery/link'
import { useTranslate } from '/machinery/I18n'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Link.css'

import chevronRight from '/images/icons/chevron-right.raw.svg'

export function Link({ href, dataX, children, targetSelf = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase
      ariaLabel={children}
      className={layoutClassName}
      {...{ href, dataX, children, targetSelf }}
    >
      {children}
    </LinkBase>
  )
}

export function LinkWithLabel({ href, dataX, children, targetSelf = undefined, layoutClassName = undefined }) {
  return (
    <LinkBase
      ariaLabel={children}
      className={cx(styles.componentWithLabel, layoutClassName)}
      {...{ href, dataX, children, targetSelf }}
    >
      <span className={styles.iconContainer}>
        <Icon icon={chevronRight} />
      </span>
      <span>{children}</span>
    </LinkBase>
  )
}

function LinkBase({ href, dataX, children, ariaLabel, targetSelf = undefined, className }) {
  const { __ } = useTranslate()

  const { openInNewTab, rel, target } = determineLinkProps({ href, targetSelf })

  return (
    <a
      data-x={dataX}
      aria-label={getAriaLabel()}
      className={cx(styles.componentBase, className)}
      {...{ href, rel, target }}
    >
      {children}
    </a>
  )

  function getAriaLabel() {
    return openInNewTab
      ? __({ x: ariaLabel })`link-to-x-in-new-window`
      : __({ x: ariaLabel })`link-to-x`
  }
}
