export async function storeHashedEmail(email) {
  const normalizedEmail = transformEmailForEC(email)
  const hashedEmail = await hashEmail(normalizedEmail)
  localStorage.setItem('hashedEmail', hashedEmail)
}

async function hashEmail(normalizedEmail) {
  const msgUint8 = new TextEncoder().encode(normalizedEmail)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

function transformEmailForEC(email) {
  const [local, domain] = email.toLowerCase().split('@')
  const [cleanLocal] = local.split('+')
  const finalLocal = ['gmail.com', 'googlemail.com'].includes(domain)
    ? cleanLocal.replace(/\./g, '')
    : cleanLocal

  return `${finalLocal}@${domain}`
}
