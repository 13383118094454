import { error } from '@kaliber/forms/validation'
import { isValidPhoneNumber } from 'libphonenumber-js/max'
import { getFileExtension } from '/machinery/getFileExtension'

export const requiredTrimmed = (fieldName) => (x) => !x.trim() && x !== false && x !== 0 && error('requiredWithFieldName', fieldName)

export function requireChecked(x, errorId = 'checkRequired') {
  return !x && error(errorId)
}

export const cvRequired = x => !x && x !== false && x !== 0 && error('cvRequired')

export function requireTermsAndConditions(x) {
  return requireChecked(x, 'requiredTermsAndConditions')
}

export const emailRequired = (fieldName) => (x) => !x.trim() && x !== false && x !== 0 && error('emailRequired')


export const phoneRequired = (fieldName) => (x) => {
  const hasPhoneNumer = typeof x === 'object' && x.phoneNumber.trim()
  const hasPhoneDialCode = typeof x === 'object' && x.phoneDialCode
  return (!hasPhoneNumer || !hasPhoneDialCode) && error('phoneRequired')
}

export function phoneValid({ phoneNumber, phoneIsoCode }) {
  const validPhoneNumber = isValidPhoneNumber(phoneNumber, phoneIsoCode.toUpperCase())
  return !validPhoneNumber && error('phoneInvalid')
}

export function fileEmpty(x) {
  return (x && x.size < 60) && error('fileEmpty')
}

export function maxFileSize(maxSize) {
  return x => (x && x.size > maxSize) && error('maxFileSize', maxSize)
}

export function fileExtension(extensions) {
  return x => {
    if (!x) return

    const ext = getFileExtension(x.name)
    return (!ext || !extensions.includes(`.${ext.toLowerCase()}`)) &&
      error('fileExtension', extensions)
  }
}

const emailRegex = /^[^\s,@]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,}$/
export const email = x => x && !emailRegex.test(x) && error('email')
