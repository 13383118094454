// This file should be synced on two places. `src/machinery/iso-countries-list.js` and `services/machinery/iso-countries-list.js`
const isoCountries = {
  'AD': {
    'label': 'Andorra',
    'iso3': 'AND',
    'iso2': 'AD',
    'dialCode': '376',
    'exampleNumber': {
      'national': '312 345',
      'international': '+376 312 345'
    }
  },
  'AE': {
    'label': 'United Arab Emirates (دولة الإمارات العربية المتحدة)',
    'iso3': 'ARE',
    'iso2': 'AE',
    'dialCode': '971',
    'exampleNumber': {
      'national': '050 123 4567',
      'international': '+971 50 123 4567'
    }
  },
  'AF': {
    'label': 'Afghanistan (افغانستان)',
    'iso3': 'AFG',
    'iso2': 'AF',
    'dialCode': '93',
    'exampleNumber': {
      'national': '070 123 4567',
      'international': '+93 70 123 4567'
    }
  },
  'AG': {
    'label': 'Antigua and Barbuda',
    'iso3': 'ATG',
    'iso2': 'AG',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(268) 464-1234',
      'international': '+1 268 464 1234'
    }
  },
  'AI': {
    'label': 'Anguilla',
    'iso3': 'AIA',
    'iso2': 'AI',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(264) 235-1234',
      'international': '+1 264 235 1234'
    }
  },
  'AL': {
    'label': 'Albania (Shqipëria)',
    'iso3': 'ALB',
    'iso2': 'AL',
    'dialCode': '355',
    'exampleNumber': {
      'national': '067 212 3456',
      'international': '+355 67 212 3456'
    }
  },
  'AM': {
    'label': 'Armenia (Հայաստան)',
    'iso3': 'ARM',
    'iso2': 'AM',
    'dialCode': '374',
    'exampleNumber': {
      'national': '077 123456',
      'international': '+374 77 123456'
    }
  },
  'AO': {
    'label': 'Angola',
    'iso3': 'AGO',
    'iso2': 'AO',
    'dialCode': '244',
    'exampleNumber': {
      'national': '923 123 456',
      'international': '+244 923 123 456'
    }
  },
  'AR': {
    'label': 'Argentina',
    'iso3': 'ARG',
    'iso2': 'AR',
    'dialCode': '54',
    'exampleNumber': {
      'national': '011 15-2345-6789',
      'international': '+54 9 11 2345 6789'
    }
  },
  'AS': {
    'label': 'American Samoa',
    'iso3': 'ASM',
    'iso2': 'AS',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(684) 733-1234',
      'international': '+1 684 733 1234'
    }
  },
  'AT': {
    'label': 'Austria',
    'iso3': 'AUT',
    'iso2': 'AT',
    'dialCode': '43',
    'exampleNumber': {
      'national': '0664 123456',
      'international': '+43 664 123456'
    }
  },
  'AU': {
    'label': 'Australia',
    'iso3': 'AUS',
    'iso2': 'AU',
    'dialCode': '61',
    'exampleNumber': {
      'national': '0412 345 678',
      'international': '+61 412 345 678'
    }
  },
  'AW': {
    'label': 'Aruba',
    'iso3': 'ABW',
    'iso2': 'AW',
    'dialCode': '297',
    'exampleNumber': {
      'national': '560 1234',
      'international': '+297 560 1234'
    }
  },
  'AX': {
    'label': 'Åland Islands (Åland)',
    'iso3': 'ALA',
    'iso2': 'AX',
    'dialCode': '358',
    'exampleNumber': {
      'national': '041 2345678',
      'international': '+358 41 2345678'
    }
  },
  'AZ': {
    'label': 'Azerbaijan (Azərbaycan)',
    'iso3': 'AZE',
    'iso2': 'AZ',
    'dialCode': '994',
    'exampleNumber': {
      'national': '040 123 45 67',
      'international': '+994 40 123 45 67'
    }
  },
  'BA': {
    'label': 'Bosnia and Herzegovina (Bosna i Hercegovina)',
    'iso3': 'BIH',
    'iso2': 'BA',
    'dialCode': '387',
    'exampleNumber': {
      'national': '061 123 456',
      'international': '+387 61 123 456'
    }
  },
  'BB': {
    'label': 'Barbados',
    'iso3': 'BRB',
    'iso2': 'BB',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(246) 250-1234',
      'international': '+1 246 250 1234'
    }
  },
  'BD': {
    'label': 'Bangladesh (বাংলাদেশ)',
    'iso3': 'BGD',
    'iso2': 'BD',
    'dialCode': '880',
    'exampleNumber': {
      'national': '01812-345678',
      'international': '+880 1812 345678'
    }
  },
  'BE': {
    'label': 'Belgium (Belgien)',
    'iso3': 'BEL',
    'iso2': 'BE',
    'dialCode': '32',
    'exampleNumber': {
      'national': '0470 12 34 56',
      'international': '+32 470 12 34 56'
    }
  },
  'BF': {
    'label': 'Burkina Faso',
    'iso3': 'BFA',
    'iso2': 'BF',
    'dialCode': '226',
    'exampleNumber': {
      'national': '70 12 34 56',
      'international': '+226 70 12 34 56'
    }
  },
  'BG': {
    'label': 'Bulgaria (България)',
    'iso3': 'BGR',
    'iso2': 'BG',
    'dialCode': '359',
    'exampleNumber': {
      'national': '043 012 345',
      'international': '+359 43 012 345'
    }
  },
  'BH': {
    'label': 'Bahrain (‏البحرين)',
    'iso3': 'BHR',
    'iso2': 'BH',
    'dialCode': '973',
    'exampleNumber': {
      'national': '3600 1234',
      'international': '+973 3600 1234'
    }
  },
  'BI': {
    'label': 'Burundi',
    'iso3': 'BDI',
    'iso2': 'BI',
    'dialCode': '257',
    'exampleNumber': {
      'national': '79 56 12 34',
      'international': '+257 79 56 12 34'
    }
  },
  'BJ': {
    'label': 'Benin (Bénin)',
    'iso3': 'BEN',
    'iso2': 'BJ',
    'dialCode': '229',
    'exampleNumber': {
      'national': '90 01 12 34',
      'international': '+229 90 01 12 34'
    }
  },
  'BL': {
    'label': 'Saint Barthélemy (Saint-Barthélemy)',
    'iso3': 'BLM',
    'iso2': 'BL',
    'dialCode': '590',
    'exampleNumber': {
      'national': '0690 00 12 34',
      'international': '+590 690 00 12 34'
    }
  },
  'BM': {
    'label': 'Bermuda',
    'iso3': 'BMU',
    'iso2': 'BM',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(441) 370-1234',
      'international': '+1 441 370 1234'
    }
  },
  'BN': {
    'label': 'Brunei (Negara Brunei Darussalam)',
    'iso3': 'BRN',
    'iso2': 'BN',
    'dialCode': '673',
    'exampleNumber': {
      'national': '712 3456',
      'international': '+673 712 3456'
    }
  },
  'BO': {
    'label': 'Bolivia (Wuliwya)',
    'iso3': 'BOL',
    'iso2': 'BO',
    'dialCode': '591',
    'exampleNumber': {
      'national': '71234567',
      'international': '+591 71234567'
    }
  },
  'BQ': {
    'label': 'Caribbean Netherlands',
    'iso3': 'BES',
    'iso2': 'BQ',
    'dialCode': '599',
    'exampleNumber': {
      'national': '318 1234',
      'international': '+599 318 1234'
    }
  },
  'BR': {
    'label': 'Brazil (Brasil)',
    'iso3': 'BRA',
    'iso2': 'BR',
    'dialCode': '55',
    'exampleNumber': {
      'national': '(11) 96123-4567',
      'international': '+55 11 96123 4567'
    }
  },
  'BS': {
    'label': 'Bahamas',
    'iso3': 'BHS',
    'iso2': 'BS',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(242) 359-1234',
      'international': '+1 242 359 1234'
    }
  },
  'BT': {
    'label': 'Bhutan (འབྲུག་ཡུལ་)',
    'iso3': 'BTN',
    'iso2': 'BT',
    'dialCode': '975',
    'exampleNumber': {
      'national': '17 12 34 56',
      'international': '+975 17 12 34 56'
    }
  },
  'BW': {
    'label': 'Botswana',
    'iso3': 'BWA',
    'iso2': 'BW',
    'dialCode': '267',
    'exampleNumber': {
      'national': '71 123 456',
      'international': '+267 71 123 456'
    }
  },
  'BY': {
    'label': 'Belarus (Белару́сь)',
    'iso3': 'BLR',
    'iso2': 'BY',
    'dialCode': '375',
    'exampleNumber': {
      'national': '8 029 491-19-11',
      'international': '+375 29 491 19 11'
    }
  },
  'BZ': {
    'label': 'Belize',
    'iso3': 'BLZ',
    'iso2': 'BZ',
    'dialCode': '501',
    'exampleNumber': {
      'national': '622-1234',
      'international': '+501 622 1234'
    }
  },
  'CA': {
    'label': 'Canada',
    'iso3': 'CAN',
    'iso2': 'CA',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(506) 234-5678',
      'international': '+1 506 234 5678'
    }
  },
  'CC': {
    'label': 'Cocos (Keeling) Islands',
    'iso3': 'CCK',
    'iso2': 'CC',
    'dialCode': '61',
    'exampleNumber': {
      'national': '0412 345 678',
      'international': '+61 412 345 678'
    }
  },
  'CD': {
    'label': 'DR Congo (RD Congo)',
    'iso3': 'COD',
    'iso2': 'CD',
    'dialCode': '243',
    'exampleNumber': {
      'national': '0991 234 567',
      'international': '+243 991 234 567'
    }
  },
  'CF': {
    'label': 'Central African Republic (République centrafricaine)',
    'iso3': 'CAF',
    'iso2': 'CF',
    'dialCode': '236',
    'exampleNumber': {
      'national': '70 01 23 45',
      'international': '+236 70 01 23 45'
    }
  },
  'CG': {
    'label': 'Republic of the Congo (République du Congo)',
    'iso3': 'COG',
    'iso2': 'CG',
    'dialCode': '242',
    'exampleNumber': {
      'national': '06 123 4567',
      'international': '+242 06 123 4567'
    }
  },
  'CH': {
    'label': 'Switzerland (Suisse)',
    'iso3': 'CHE',
    'iso2': 'CH',
    'dialCode': '41',
    'exampleNumber': {
      'national': '078 123 45 67',
      'international': '+41 78 123 45 67'
    }
  },
  'CI': {
    'label': "Ivory Coast (Côte d'Ivoire)",
    'iso3': 'CIV',
    'iso2': 'CI',
    'dialCode': '225',
    'exampleNumber': {
      'national': '01 23 45 6789',
      'international': '+225 01 23 45 6789'
    }
  },
  'CK': {
    'label': 'Cook Islands',
    'iso3': 'COK',
    'iso2': 'CK',
    'dialCode': '682',
    'exampleNumber': {
      'national': '71 234',
      'international': '+682 71 234'
    }
  },
  'CL': {
    'label': 'Chile',
    'iso3': 'CHL',
    'iso2': 'CL',
    'dialCode': '56',
    'exampleNumber': {
      'national': '(2) 2123 4567',
      'international': '+56 2 2123 4567'
    }
  },
  'CM': {
    'label': 'Cameroon',
    'iso3': 'CMR',
    'iso2': 'CM',
    'dialCode': '237',
    'exampleNumber': {
      'national': '6 71 23 45 67',
      'international': '+237 6 71 23 45 67'
    }
  },
  'CN': {
    'label': 'China (中国)',
    'iso3': 'CHN',
    'iso2': 'CN',
    'dialCode': '86',
    'exampleNumber': {
      'national': '131 2345 6789',
      'international': '+86 131 2345 6789'
    }
  },
  'CO': {
    'label': 'Colombia',
    'iso3': 'COL',
    'iso2': 'CO',
    'dialCode': '57',
    'exampleNumber': {
      'national': '321 1234567',
      'international': '+57 321 1234567'
    }
  },
  'CR': {
    'label': 'Costa Rica',
    'iso3': 'CRI',
    'iso2': 'CR',
    'dialCode': '506',
    'exampleNumber': {
      'national': '8312 3456',
      'international': '+506 8312 3456'
    }
  },
  'CU': {
    'label': 'Cuba',
    'iso3': 'CUB',
    'iso2': 'CU',
    'dialCode': '53',
    'exampleNumber': {
      'national': '05 1234567',
      'international': '+53 5 1234567'
    }
  },
  'CV': {
    'label': 'Cape Verde (Cabo Verde)',
    'iso3': 'CPV',
    'iso2': 'CV',
    'dialCode': '238',
    'exampleNumber': {
      'national': '991 12 34',
      'international': '+238 991 12 34'
    }
  },
  'CW': {
    'label': 'Curaçao',
    'iso3': 'CUW',
    'iso2': 'CW',
    'dialCode': '599',
    'exampleNumber': {
      'national': '9 518 1234',
      'international': '+599 9 518 1234'
    }
  },
  'CX': {
    'label': 'Christmas Island',
    'iso3': 'CXR',
    'iso2': 'CX',
    'dialCode': '61',
    'exampleNumber': {
      'national': '0412 345 678',
      'international': '+61 412 345 678'
    }
  },
  'CY': {
    'label': 'Cyprus (Κύπρος)',
    'iso3': 'CYP',
    'iso2': 'CY',
    'dialCode': '357',
    'exampleNumber': {
      'national': '96 123456',
      'international': '+357 96 123456'
    }
  },
  'CZ': {
    'label': 'Czechia (Česko)',
    'iso3': 'CZE',
    'iso2': 'CZ',
    'dialCode': '420',
    'exampleNumber': {
      'national': '601 123 456',
      'international': '+420 601 123 456'
    }
  },
  'DE': {
    'label': 'Germany (Deutschland)',
    'iso3': 'DEU',
    'iso2': 'DE',
    'dialCode': '49',
    'exampleNumber': {
      'national': '01512 3456789',
      'international': '+49 1512 3456789'
    }
  },
  'DJ': {
    'label': 'Djibouti (جيبوتي‎)',
    'iso3': 'DJI',
    'iso2': 'DJ',
    'dialCode': '253',
    'exampleNumber': {
      'national': '77 83 10 01',
      'international': '+253 77 83 10 01'
    }
  },
  'DK': {
    'label': 'Denmark (Danmark)',
    'iso3': 'DNK',
    'iso2': 'DK',
    'dialCode': '45',
    'exampleNumber': {
      'national': '32 12 34 56',
      'international': '+45 32 12 34 56'
    }
  },
  'DM': {
    'label': 'Dominica',
    'iso3': 'DMA',
    'iso2': 'DM',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(767) 225-1234',
      'international': '+1 767 225 1234'
    }
  },
  'DO': {
    'label': 'Dominican Republic (República Dominicana)',
    'iso3': 'DOM',
    'iso2': 'DO',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(809) 234-5678',
      'international': '+1 809 234 5678'
    }
  },
  'DZ': {
    'label': 'Algeria (الجزائر)',
    'iso3': 'DZA',
    'iso2': 'DZ',
    'dialCode': '213',
    'exampleNumber': {
      'national': '0551 23 45 67',
      'international': '+213 551 23 45 67'
    }
  },
  'EC': {
    'label': 'Ecuador',
    'iso3': 'ECU',
    'iso2': 'EC',
    'dialCode': '593',
    'exampleNumber': {
      'national': '099 123 4567',
      'international': '+593 99 123 4567'
    }
  },
  'EE': {
    'label': 'Estonia (Eesti)',
    'iso3': 'EST',
    'iso2': 'EE',
    'dialCode': '372',
    'exampleNumber': {
      'national': '5123 4567',
      'international': '+372 5123 4567'
    }
  },
  'EG': {
    'label': 'Egypt (مصر)',
    'iso3': 'EGY',
    'iso2': 'EG',
    'dialCode': '20',
    'exampleNumber': {
      'national': '010 01234567',
      'international': '+20 10 01234567'
    }
  },
  'EH': {
    'label': 'Western Sahara',
    'iso3': 'ESH',
    'iso2': 'EH',
    'dialCode': '212',
    'exampleNumber': {
      'national': '0650-123456',
      'international': '+212 650 123456'
    }
  },
  'ER': {
    'label': 'Eritrea (إرتريا‎)',
    'iso3': 'ERI',
    'iso2': 'ER',
    'dialCode': '291',
    'exampleNumber': {
      'national': '07 123 456',
      'international': '+291 7 123 456'
    }
  },
  'ES': {
    'label': 'Spain (España)',
    'iso3': 'ESP',
    'iso2': 'ES',
    'dialCode': '34',
    'exampleNumber': {
      'national': '612 34 56 78',
      'international': '+34 612 34 56 78'
    }
  },
  'ET': {
    'label': 'Ethiopia (ኢትዮጵያ)',
    'iso3': 'ETH',
    'iso2': 'ET',
    'dialCode': '251',
    'exampleNumber': {
      'national': '091 123 4567',
      'international': '+251 91 123 4567'
    }
  },
  'FI': {
    'label': 'Finland (Suomi)',
    'iso3': 'FIN',
    'iso2': 'FI',
    'dialCode': '358',
    'exampleNumber': {
      'national': '041 2345678',
      'international': '+358 41 2345678'
    }
  },
  'FJ': {
    'label': 'Fiji',
    'iso3': 'FJI',
    'iso2': 'FJ',
    'dialCode': '679',
    'exampleNumber': {
      'national': '701 2345',
      'international': '+679 701 2345'
    }
  },
  'FK': {
    'label': 'Falkland Islands',
    'iso3': 'FLK',
    'iso2': 'FK',
    'dialCode': '500',
    'exampleNumber': {
      'national': '51234',
      'international': '+500 51234'
    }
  },
  'FM': {
    'label': 'Micronesia',
    'iso3': 'FSM',
    'iso2': 'FM',
    'dialCode': '691',
    'exampleNumber': {
      'national': '350 1234',
      'international': '+691 350 1234'
    }
  },
  'FO': {
    'label': 'Faroe Islands (Færøerne)',
    'iso3': 'FRO',
    'iso2': 'FO',
    'dialCode': '298',
    'exampleNumber': {
      'national': '211234',
      'international': '+298 211234'
    }
  },
  'FR': {
    'label': 'France',
    'iso3': 'FRA',
    'iso2': 'FR',
    'dialCode': '33',
    'exampleNumber': {
      'national': '06 12 34 56 78',
      'international': '+33 6 12 34 56 78'
    }
  },
  'GA': {
    'label': 'Gabon',
    'iso3': 'GAB',
    'iso2': 'GA',
    'dialCode': '241',
    'exampleNumber': {
      'national': '06 03 12 34',
      'international': '+241 06 03 12 34'
    }
  },
  'GB': {
    'label': 'United Kingdom',
    'iso3': 'GBR',
    'iso2': 'GB',
    'dialCode': '44',
    'exampleNumber': {
      'national': '07400 123456',
      'international': '+44 7400 123456'
    }
  },
  'GD': {
    'label': 'Grenada',
    'iso3': 'GRD',
    'iso2': 'GD',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(473) 403-1234',
      'international': '+1 473 403 1234'
    }
  },
  'GE': {
    'label': 'Georgia (საქართველო)',
    'iso3': 'GEO',
    'iso2': 'GE',
    'dialCode': '995',
    'exampleNumber': {
      'national': '555 12 34 56',
      'international': '+995 555 12 34 56'
    }
  },
  'GF': {
    'label': 'French Guiana (Guyane française)',
    'iso3': 'GUF',
    'iso2': 'GF',
    'dialCode': '594',
    'exampleNumber': {
      'national': '0694 20 12 34',
      'international': '+594 694 20 12 34'
    }
  },
  'GG': {
    'label': 'Guernsey',
    'iso3': 'GGY',
    'iso2': 'GG',
    'dialCode': '44',
    'exampleNumber': {
      'national': '07781 123456',
      'international': '+44 7781 123456'
    }
  },
  'GH': {
    'label': 'Ghana',
    'iso3': 'GHA',
    'iso2': 'GH',
    'dialCode': '233',
    'exampleNumber': {
      'national': '023 123 4567',
      'international': '+233 23 123 4567'
    }
  },
  'GI': {
    'label': 'Gibraltar',
    'iso3': 'GIB',
    'iso2': 'GI',
    'dialCode': '350',
    'exampleNumber': {
      'national': '57123456',
      'international': '+350 57123456'
    }
  },
  'GL': {
    'label': 'Greenland (Kalaallit Nunaat)',
    'iso3': 'GRL',
    'iso2': 'GL',
    'dialCode': '299',
    'exampleNumber': {
      'national': '22 12 34',
      'international': '+299 22 12 34'
    }
  },
  'GM': {
    'label': 'Gambia',
    'iso3': 'GMB',
    'iso2': 'GM',
    'dialCode': '220',
    'exampleNumber': {
      'national': '301 2345',
      'international': '+220 301 2345'
    }
  },
  'GN': {
    'label': 'Guinea (Guinée)',
    'iso3': 'GIN',
    'iso2': 'GN',
    'dialCode': '224',
    'exampleNumber': {
      'national': '601 12 34 56',
      'international': '+224 601 12 34 56'
    }
  },
  'GP': {
    'label': 'Guadeloupe',
    'iso3': 'GLP',
    'iso2': 'GP',
    'dialCode': '590',
    'exampleNumber': {
      'national': '0690 00 12 34',
      'international': '+590 690 00 12 34'
    }
  },
  'GQ': {
    'label': 'Equatorial Guinea (Guinée équatoriale)',
    'iso3': 'GNQ',
    'iso2': 'GQ',
    'dialCode': '240',
    'exampleNumber': {
      'national': '222 123 456',
      'international': '+240 222 123 456'
    }
  },
  'GR': {
    'label': 'Greece (Ελλάδα)',
    'iso3': 'GRC',
    'iso2': 'GR',
    'dialCode': '30',
    'exampleNumber': {
      'national': '691 234 5678',
      'international': '+30 691 234 5678'
    }
  },
  'GT': {
    'label': 'Guatemala',
    'iso3': 'GTM',
    'iso2': 'GT',
    'dialCode': '502',
    'exampleNumber': {
      'national': '5123 4567',
      'international': '+502 5123 4567'
    }
  },
  'GU': {
    'label': 'Guam (Guåhån)',
    'iso3': 'GUM',
    'iso2': 'GU',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(671) 300-1234',
      'international': '+1 671 300 1234'
    }
  },
  'GW': {
    'label': 'Guinea-Bissau (Guiné-Bissau)',
    'iso3': 'GNB',
    'iso2': 'GW',
    'dialCode': '245',
    'exampleNumber': {
      'national': '955 012 345',
      'international': '+245 955 012 345'
    }
  },
  'GY': {
    'label': 'Guyana',
    'iso3': 'GUY',
    'iso2': 'GY',
    'dialCode': '592',
    'exampleNumber': {
      'national': '609 1234',
      'international': '+592 609 1234'
    }
  },
  'HK': {
    'label': 'Hong Kong',
    'iso3': 'HKG',
    'iso2': 'HK',
    'dialCode': '852',
    'exampleNumber': {
      'national': '5123 4567',
      'international': '+852 5123 4567'
    }
  },
  'HN': {
    'label': 'Honduras',
    'iso3': 'HND',
    'iso2': 'HN',
    'dialCode': '504',
    'exampleNumber': {
      'national': '9123-4567',
      'international': '+504 9123 4567'
    }
  },
  'HR': {
    'label': 'Croatia (Hrvatska)',
    'iso3': 'HRV',
    'iso2': 'HR',
    'dialCode': '385',
    'exampleNumber': {
      'national': '092 123 4567',
      'international': '+385 92 123 4567'
    }
  },
  'HT': {
    'label': 'Haiti (Haïti)',
    'iso3': 'HTI',
    'iso2': 'HT',
    'dialCode': '509',
    'exampleNumber': {
      'national': '34 10 1234',
      'international': '+509 34 10 1234'
    }
  },
  'HU': {
    'label': 'Hungary (Magyarország)',
    'iso3': 'HUN',
    'iso2': 'HU',
    'dialCode': '36',
    'exampleNumber': {
      'national': '06 20 123 4567',
      'international': '+36 20 123 4567'
    }
  },
  'ID': {
    'label': 'Indonesia',
    'iso3': 'IDN',
    'iso2': 'ID',
    'dialCode': '62',
    'exampleNumber': {
      'national': '0812-345-678',
      'international': '+62 812 345 678'
    }
  },
  'IE': {
    'label': 'Ireland',
    'iso3': 'IRL',
    'iso2': 'IE',
    'dialCode': '353',
    'exampleNumber': {
      'national': '085 012 3456',
      'international': '+353 85 012 3456'
    }
  },
  'IL': {
    'label': 'Israel (إسرائيل)',
    'iso3': 'ISR',
    'iso2': 'IL',
    'dialCode': '972',
    'exampleNumber': {
      'national': '050-234-5678',
      'international': '+972 50 234 5678'
    }
  },
  'IM': {
    'label': 'Isle of Man',
    'iso3': 'IMN',
    'iso2': 'IM',
    'dialCode': '44',
    'exampleNumber': {
      'national': '07924 123456',
      'international': '+44 7924 123456'
    }
  },
  'IN': {
    'label': 'India',
    'iso3': 'IND',
    'iso2': 'IN',
    'dialCode': '91',
    'exampleNumber': {
      'national': '081234 56789',
      'international': '+91 81234 56789'
    }
  },
  'IO': {
    'label': 'British Indian Ocean Territory',
    'iso3': 'IOT',
    'iso2': 'IO',
    'dialCode': '246',
    'exampleNumber': {
      'national': '380 1234',
      'international': '+246 380 1234'
    }
  },
  'IQ': {
    'label': 'Iraq (العراق)',
    'iso3': 'IRQ',
    'iso2': 'IQ',
    'dialCode': '964',
    'exampleNumber': {
      'national': '0791 234 5678',
      'international': '+964 791 234 5678'
    }
  },
  'IR': {
    'label': 'Iran (ایران)',
    'iso3': 'IRN',
    'iso2': 'IR',
    'dialCode': '98',
    'exampleNumber': {
      'national': '0912 345 6789',
      'international': '+98 912 345 6789'
    }
  },
  'IS': {
    'label': 'Iceland (Ísland)',
    'iso3': 'ISL',
    'iso2': 'IS',
    'dialCode': '354',
    'exampleNumber': {
      'national': '611 1234',
      'international': '+354 611 1234'
    }
  },
  'IT': {
    'label': 'Italy (Italia)',
    'iso3': 'ITA',
    'iso2': 'IT',
    'dialCode': '39',
    'exampleNumber': {
      'national': '312 345 6789',
      'international': '+39 312 345 6789'
    }
  },
  'JE': {
    'label': 'Jersey',
    'iso3': 'JEY',
    'iso2': 'JE',
    'dialCode': '44',
    'exampleNumber': {
      'national': '07797 712345',
      'international': '+44 7797 712345'
    }
  },
  'JM': {
    'label': 'Jamaica',
    'iso3': 'JAM',
    'iso2': 'JM',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(876) 210-1234',
      'international': '+1 876 210 1234'
    }
  },
  'JO': {
    'label': 'Jordan (الأردن)',
    'iso3': 'JOR',
    'iso2': 'JO',
    'dialCode': '962',
    'exampleNumber': {
      'national': '07 9012 3456',
      'international': '+962 7 9012 3456'
    }
  },
  'JP': {
    'label': 'Japan (日本)',
    'iso3': 'JPN',
    'iso2': 'JP',
    'dialCode': '81',
    'exampleNumber': {
      'national': '090-1234-5678',
      'international': '+81 90 1234 5678'
    }
  },
  'KE': {
    'label': 'Kenya',
    'iso3': 'KEN',
    'iso2': 'KE',
    'dialCode': '254',
    'exampleNumber': {
      'national': '0712 123456',
      'international': '+254 712 123456'
    }
  },
  'KG': {
    'label': 'Kyrgyzstan (Кыргызстан)',
    'iso3': 'KGZ',
    'iso2': 'KG',
    'dialCode': '996',
    'exampleNumber': {
      'national': '0700 123 456',
      'international': '+996 700 123 456'
    }
  },
  'KH': {
    'label': 'Cambodia (Kâmpŭchéa)',
    'iso3': 'KHM',
    'iso2': 'KH',
    'dialCode': '855',
    'exampleNumber': {
      'national': '091 234 567',
      'international': '+855 91 234 567'
    }
  },
  'KI': {
    'label': 'Kiribati',
    'iso3': 'KIR',
    'iso2': 'KI',
    'dialCode': '686',
    'exampleNumber': {
      'national': '72001234',
      'international': '+686 72001234'
    }
  },
  'KM': {
    'label': 'Comoros (القمر‎)',
    'iso3': 'COM',
    'iso2': 'KM',
    'dialCode': '269',
    'exampleNumber': {
      'national': '321 23 45',
      'international': '+269 321 23 45'
    }
  },
  'KN': {
    'label': 'Saint Kitts and Nevis',
    'iso3': 'KNA',
    'iso2': 'KN',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(869) 765-2917',
      'international': '+1 869 765 2917'
    }
  },
  'KP': {
    'label': 'North Korea (조선)',
    'iso3': 'PRK',
    'iso2': 'KP',
    'dialCode': '850',
    'exampleNumber': {
      'national': '0192 123 4567',
      'international': '+850 192 123 4567'
    }
  },
  'KR': {
    'label': 'South Korea (한국)',
    'iso3': 'KOR',
    'iso2': 'KR',
    'dialCode': '82',
    'exampleNumber': {
      'national': '010-2000-0000',
      'international': '+82 10 2000 0000'
    }
  },
  'KW': {
    'label': 'Kuwait (الكويت)',
    'iso3': 'KWT',
    'iso2': 'KW',
    'dialCode': '965',
    'exampleNumber': {
      'national': '500 12345',
      'international': '+965 500 12345'
    }
  },
  'KY': {
    'label': 'Cayman Islands',
    'iso3': 'CYM',
    'iso2': 'KY',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(345) 323-1234',
      'international': '+1 345 323 1234'
    }
  },
  'KZ': {
    'label': 'Kazakhstan (Қазақстан)',
    'iso3': 'KAZ',
    'iso2': 'KZ',
    'dialCode': '7',
    'exampleNumber': {
      'national': '8 (771) 000 9998',
      'international': '+7 771 000 9998'
    }
  },
  'LA': {
    'label': 'Laos (ສປປລາວ)',
    'iso3': 'LAO',
    'iso2': 'LA',
    'dialCode': '856',
    'exampleNumber': {
      'national': '020 23 123 456',
      'international': '+856 20 23 123 456'
    }
  },
  'LB': {
    'label': 'Lebanon (لبنان)',
    'iso3': 'LBN',
    'iso2': 'LB',
    'dialCode': '961',
    'exampleNumber': {
      'national': '71 123 456',
      'international': '+961 71 123 456'
    }
  },
  'LC': {
    'label': 'Saint Lucia',
    'iso3': 'LCA',
    'iso2': 'LC',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(758) 284-5678',
      'international': '+1 758 284 5678'
    }
  },
  'LI': {
    'label': 'Liechtenstein',
    'iso3': 'LIE',
    'iso2': 'LI',
    'dialCode': '423',
    'exampleNumber': {
      'national': '660 234 567',
      'international': '+423 660 234 567'
    }
  },
  'LK': {
    'label': 'Sri Lanka (ශ්‍රී ලංකාව)',
    'iso3': 'LKA',
    'iso2': 'LK',
    'dialCode': '94',
    'exampleNumber': {
      'national': '071 234 5678',
      'international': '+94 71 234 5678'
    }
  },
  'LR': {
    'label': 'Liberia',
    'iso3': 'LBR',
    'iso2': 'LR',
    'dialCode': '231',
    'exampleNumber': {
      'national': '077 012 3456',
      'international': '+231 77 012 3456'
    }
  },
  'LS': {
    'label': 'Lesotho',
    'iso3': 'LSO',
    'iso2': 'LS',
    'dialCode': '266',
    'exampleNumber': {
      'national': '5012 3456',
      'international': '+266 5012 3456'
    }
  },
  'LT': {
    'label': 'Lithuania (Lietuva)',
    'iso3': 'LTU',
    'iso2': 'LT',
    'dialCode': '370',
    'exampleNumber': {
      'national': '(8-612) 34567',
      'international': '+370 612 34567'
    }
  },
  'LU': {
    'label': 'Luxembourg (Luxemburg)',
    'iso3': 'LUX',
    'iso2': 'LU',
    'dialCode': '352',
    'exampleNumber': {
      'national': '628 123 456',
      'international': '+352 628 123 456'
    }
  },
  'LV': {
    'label': 'Latvia (Latvija)',
    'iso3': 'LVA',
    'iso2': 'LV',
    'dialCode': '371',
    'exampleNumber': {
      'national': '21 234 567',
      'international': '+371 21 234 567'
    }
  },
  'LY': {
    'label': 'Libya (‏ليبيا)',
    'iso3': 'LBY',
    'iso2': 'LY',
    'dialCode': '218',
    'exampleNumber': {
      'national': '091-2345678',
      'international': '+218 91 2345678'
    }
  },
  'MA': {
    'label': 'Morocco (المغرب)',
    'iso3': 'MAR',
    'iso2': 'MA',
    'dialCode': '212',
    'exampleNumber': {
      'national': '0650-123456',
      'international': '+212 650 123456'
    }
  },
  'MC': {
    'label': 'Monaco',
    'iso3': 'MCO',
    'iso2': 'MC',
    'dialCode': '377',
    'exampleNumber': {
      'national': '06 12 34 56 78',
      'international': '+377 6 12 34 56 78'
    }
  },
  'MD': {
    'label': 'Moldova',
    'iso3': 'MDA',
    'iso2': 'MD',
    'dialCode': '373',
    'exampleNumber': {
      'national': '0621 12 345',
      'international': '+373 621 12 345'
    }
  },
  'ME': {
    'label': 'Montenegro (Црна Гора)',
    'iso3': 'MNE',
    'iso2': 'ME',
    'dialCode': '382',
    'exampleNumber': {
      'national': '067 622 901',
      'international': '+382 67 622 901'
    }
  },
  'MF': {
    'label': 'Saint Martin (Saint-Martin)',
    'iso3': 'MAF',
    'iso2': 'MF',
    'dialCode': '590',
    'exampleNumber': {
      'national': '0690 00 12 34',
      'international': '+590 690 00 12 34'
    }
  },
  'MG': {
    'label': 'Madagascar',
    'iso3': 'MDG',
    'iso2': 'MG',
    'dialCode': '261',
    'exampleNumber': {
      'national': '032 12 345 67',
      'international': '+261 32 12 345 67'
    }
  },
  'MH': {
    'label': 'Marshall Islands',
    'iso3': 'MHL',
    'iso2': 'MH',
    'dialCode': '692',
    'exampleNumber': {
      'national': '235-1234',
      'international': '+692 235 1234'
    }
  },
  'MK': {
    'label': 'North Macedonia (Македонија)',
    'iso3': 'MKD',
    'iso2': 'MK',
    'dialCode': '389',
    'exampleNumber': {
      'national': '072 345 678',
      'international': '+389 72 345 678'
    }
  },
  'ML': {
    'label': 'Mali',
    'iso3': 'MLI',
    'iso2': 'ML',
    'dialCode': '223',
    'exampleNumber': {
      'national': '65 01 23 45',
      'international': '+223 65 01 23 45'
    }
  },
  'MM': {
    'label': 'Myanmar (မြန်မာ)',
    'iso3': 'MMR',
    'iso2': 'MM',
    'dialCode': '95',
    'exampleNumber': {
      'national': '09 212 3456',
      'international': '+95 9 212 3456'
    }
  },
  'MN': {
    'label': 'Mongolia (Монгол улс)',
    'iso3': 'MNG',
    'iso2': 'MN',
    'dialCode': '976',
    'exampleNumber': {
      'national': '8812 3456',
      'international': '+976 8812 3456'
    }
  },
  'MO': {
    'label': 'Macau',
    'iso3': 'MAC',
    'iso2': 'MO',
    'dialCode': '853',
    'exampleNumber': {
      'national': '6612 3456',
      'international': '+853 6612 3456'
    }
  },
  'MP': {
    'label': 'Northern Mariana Islands',
    'iso3': 'MNP',
    'iso2': 'MP',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(670) 234-5678',
      'international': '+1 670 234 5678'
    }
  },
  'MQ': {
    'label': 'Martinique',
    'iso3': 'MTQ',
    'iso2': 'MQ',
    'dialCode': '596',
    'exampleNumber': {
      'national': '0696 20 12 34',
      'international': '+596 696 20 12 34'
    }
  },
  'MR': {
    'label': 'Mauritania (موريتانيا)',
    'iso3': 'MRT',
    'iso2': 'MR',
    'dialCode': '222',
    'exampleNumber': {
      'national': '22 12 34 56',
      'international': '+222 22 12 34 56'
    }
  },
  'MS': {
    'label': 'Montserrat',
    'iso3': 'MSR',
    'iso2': 'MS',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(664) 492-3456',
      'international': '+1 664 492 3456'
    }
  },
  'MT': {
    'label': 'Malta',
    'iso3': 'MLT',
    'iso2': 'MT',
    'dialCode': '356',
    'exampleNumber': {
      'national': '9696 1234',
      'international': '+356 9696 1234'
    }
  },
  'MU': {
    'label': 'Mauritius',
    'iso3': 'MUS',
    'iso2': 'MU',
    'dialCode': '230',
    'exampleNumber': {
      'national': '5251 2345',
      'international': '+230 5251 2345'
    }
  },
  'MV': {
    'label': 'Maldives (ދިވެހިރާއްޖޭގެ)',
    'iso3': 'MDV',
    'iso2': 'MV',
    'dialCode': '960',
    'exampleNumber': {
      'national': '771-2345',
      'international': '+960 771 2345'
    }
  },
  'MW': {
    'label': 'Malawi',
    'iso3': 'MWI',
    'iso2': 'MW',
    'dialCode': '265',
    'exampleNumber': {
      'national': '0991 23 45 67',
      'international': '+265 991 23 45 67'
    }
  },
  'MX': {
    'label': 'Mexico (México)',
    'iso3': 'MEX',
    'iso2': 'MX',
    'dialCode': '52',
    'exampleNumber': {
      'national': '222 123 4567',
      'international': '+52 222 123 4567'
    }
  },
  'MY': {
    'label': 'Malaysia',
    'iso3': 'MYS',
    'iso2': 'MY',
    'dialCode': '60',
    'exampleNumber': {
      'national': '012-345 6789',
      'international': '+60 12 345 6789'
    }
  },
  'MZ': {
    'label': 'Mozambique (Moçambique)',
    'iso3': 'MOZ',
    'iso2': 'MZ',
    'dialCode': '258',
    'exampleNumber': {
      'national': '82 123 4567',
      'international': '+258 82 123 4567'
    }
  },
  'NA': {
    'label': 'Namibia (Namibië)',
    'iso3': 'NAM',
    'iso2': 'NA',
    'dialCode': '264',
    'exampleNumber': {
      'national': '081 123 4567',
      'international': '+264 81 123 4567'
    }
  },
  'NC': {
    'label': 'New Caledonia (Nouvelle-Calédonie)',
    'iso3': 'NCL',
    'iso2': 'NC',
    'dialCode': '687',
    'exampleNumber': {
      'national': '75.12.34',
      'international': '+687 75 12 34'
    }
  },
  'NE': {
    'label': 'Niger',
    'iso3': 'NER',
    'iso2': 'NE',
    'dialCode': '227',
    'exampleNumber': {
      'national': '93 12 34 56',
      'international': '+227 93 12 34 56'
    }
  },
  'NF': {
    'label': 'Norfolk Island',
    'iso3': 'NFK',
    'iso2': 'NF',
    'dialCode': '672',
    'exampleNumber': {
      'national': '3 81234',
      'international': '+672 3 81234'
    }
  },
  'NG': {
    'label': 'Nigeria',
    'iso3': 'NGA',
    'iso2': 'NG',
    'dialCode': '234',
    'exampleNumber': {
      'national': '0802 123 4567',
      'international': '+234 802 123 4567'
    }
  },
  'NI': {
    'label': 'Nicaragua',
    'iso3': 'NIC',
    'iso2': 'NI',
    'dialCode': '505',
    'exampleNumber': {
      'national': '8123 4567',
      'international': '+505 8123 4567'
    }
  },
  'NL': {
    'label': 'Netherlands (Nederland)',
    'iso3': 'NLD',
    'iso2': 'NL',
    'dialCode': '31',
    'exampleNumber': {
      'national': '06 12345678',
      'international': '+31 6 12345678'
    }
  },
  'NO': {
    'label': 'Norway (Noreg)',
    'iso3': 'NOR',
    'iso2': 'NO',
    'dialCode': '47',
    'exampleNumber': {
      'national': '40 61 23 45',
      'international': '+47 40 61 23 45'
    }
  },
  'NP': {
    'label': 'Nepal (नेपाल)',
    'iso3': 'NPL',
    'iso2': 'NP',
    'dialCode': '977',
    'exampleNumber': {
      'national': '984-1234567',
      'international': '+977 984 1234567'
    }
  },
  'NR': {
    'label': 'Nauru',
    'iso3': 'NRU',
    'iso2': 'NR',
    'dialCode': '674',
    'exampleNumber': {
      'national': '555 1234',
      'international': '+674 555 1234'
    }
  },
  'NU': {
    'label': 'Niue',
    'iso3': 'NIU',
    'iso2': 'NU',
    'dialCode': '683',
    'exampleNumber': {
      'national': '888 4012',
      'international': '+683 888 4012'
    }
  },
  'NZ': {
    'label': 'New Zealand',
    'iso3': 'NZL',
    'iso2': 'NZ',
    'dialCode': '64',
    'exampleNumber': {
      'national': '021 123 4567',
      'international': '+64 21 123 4567'
    }
  },
  'OM': {
    'label': 'Oman (عمان)',
    'iso3': 'OMN',
    'iso2': 'OM',
    'dialCode': '968',
    'exampleNumber': {
      'national': '9212 3456',
      'international': '+968 9212 3456'
    }
  },
  'PA': {
    'label': 'Panama (Panamá)',
    'iso3': 'PAN',
    'iso2': 'PA',
    'dialCode': '507',
    'exampleNumber': {
      'national': '6123-4567',
      'international': '+507 6123 4567'
    }
  },
  'PE': {
    'label': 'Peru (Piruw)',
    'iso3': 'PER',
    'iso2': 'PE',
    'dialCode': '51',
    'exampleNumber': {
      'national': '912 345 678',
      'international': '+51 912 345 678'
    }
  },
  'PF': {
    'label': 'French Polynesia (Polynésie française)',
    'iso3': 'PYF',
    'iso2': 'PF',
    'dialCode': '689',
    'exampleNumber': {
      'national': '87 12 34 56',
      'international': '+689 87 12 34 56'
    }
  },
  'PG': {
    'label': 'Papua New Guinea',
    'iso3': 'PNG',
    'iso2': 'PG',
    'dialCode': '675',
    'exampleNumber': {
      'national': '7012 3456',
      'international': '+675 7012 3456'
    }
  },
  'PH': {
    'label': 'Philippines',
    'iso3': 'PHL',
    'iso2': 'PH',
    'dialCode': '63',
    'exampleNumber': {
      'national': '0905 123 4567',
      'international': '+63 905 123 4567'
    }
  },
  'PK': {
    'label': 'Pakistan',
    'iso3': 'PAK',
    'iso2': 'PK',
    'dialCode': '92',
    'exampleNumber': {
      'national': '0301 2345678',
      'international': '+92 301 2345678'
    }
  },
  'PL': {
    'label': 'Poland (Polska)',
    'iso3': 'POL',
    'iso2': 'PL',
    'dialCode': '48',
    'exampleNumber': {
      'national': '512 345 678',
      'international': '+48 512 345 678'
    }
  },
  'PM': {
    'label': 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    'iso3': 'SPM',
    'iso2': 'PM',
    'dialCode': '508',
    'exampleNumber': {
      'national': '055 12 34',
      'international': '+508 55 12 34'
    }
  },
  'PR': {
    'label': 'Puerto Rico',
    'iso3': 'PRI',
    'iso2': 'PR',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(787) 234-5678',
      'international': '+1 787 234 5678'
    }
  },
  'PS': {
    'label': 'Palestine (فلسطين)',
    'iso3': 'PSE',
    'iso2': 'PS',
    'dialCode': '970',
    'exampleNumber': {
      'national': '0599 123 456',
      'international': '+970 599 123 456'
    }
  },
  'PT': {
    'label': 'Portugal',
    'iso3': 'PRT',
    'iso2': 'PT',
    'dialCode': '351',
    'exampleNumber': {
      'national': '912 345 678',
      'international': '+351 912 345 678'
    }
  },
  'PW': {
    'label': 'Palau',
    'iso3': 'PLW',
    'iso2': 'PW',
    'dialCode': '680',
    'exampleNumber': {
      'national': '620 1234',
      'international': '+680 620 1234'
    }
  },
  'PY': {
    'label': 'Paraguay (Paraguái)',
    'iso3': 'PRY',
    'iso2': 'PY',
    'dialCode': '595',
    'exampleNumber': {
      'national': '0961 456789',
      'international': '+595 961 456789'
    }
  },
  'QA': {
    'label': 'Qatar (قطر)',
    'iso3': 'QAT',
    'iso2': 'QA',
    'dialCode': '974',
    'exampleNumber': {
      'national': '3312 3456',
      'international': '+974 3312 3456'
    }
  },
  'RE': {
    'label': 'Réunion (La Réunion)',
    'iso3': 'REU',
    'iso2': 'RE',
    'dialCode': '262',
    'exampleNumber': {
      'national': '0692 12 34 56',
      'international': '+262 692 12 34 56'
    }
  },
  'RO': {
    'label': 'Romania (România)',
    'iso3': 'ROU',
    'iso2': 'RO',
    'dialCode': '40',
    'exampleNumber': {
      'national': '0712 034 567',
      'international': '+40 712 034 567'
    }
  },
  'RS': {
    'label': 'Serbia (Србија)',
    'iso3': 'SRB',
    'iso2': 'RS',
    'dialCode': '381',
    'exampleNumber': {
      'national': '060 1234567',
      'international': '+381 60 1234567'
    }
  },
  'RU': {
    'label': 'Russia (Россия)',
    'iso3': 'RUS',
    'iso2': 'RU',
    'dialCode': '7',
    'exampleNumber': {
      'national': '8 (912) 345-67-89',
      'international': '+7 912 345 67 89'
    }
  },
  'RW': {
    'label': 'Rwanda',
    'iso3': 'RWA',
    'iso2': 'RW',
    'dialCode': '250',
    'exampleNumber': {
      'national': '0720 123 456',
      'international': '+250 720 123 456'
    }
  },
  'SA': {
    'label': 'Saudi Arabia (العربية السعودية)',
    'iso3': 'SAU',
    'iso2': 'SA',
    'dialCode': '966',
    'exampleNumber': {
      'national': '051 234 5678',
      'international': '+966 51 234 5678'
    }
  },
  'SB': {
    'label': 'Solomon Islands',
    'iso3': 'SLB',
    'iso2': 'SB',
    'dialCode': '677',
    'exampleNumber': {
      'national': '74 21234',
      'international': '+677 74 21234'
    }
  },
  'SC': {
    'label': 'Seychelles (Sesel)',
    'iso3': 'SYC',
    'iso2': 'SC',
    'dialCode': '248',
    'exampleNumber': {
      'national': '2 510 123',
      'international': '+248 2 510 123'
    }
  },
  'SD': {
    'label': 'Sudan (السودان)',
    'iso3': 'SDN',
    'iso2': 'SD',
    'dialCode': '249',
    'exampleNumber': {
      'national': '091 123 1234',
      'international': '+249 91 123 1234'
    }
  },
  'SE': {
    'label': 'Sweden (Sverige)',
    'iso3': 'SWE',
    'iso2': 'SE',
    'dialCode': '46',
    'exampleNumber': {
      'national': '070-123 45 67',
      'international': '+46 70 123 45 67'
    }
  },
  'SG': {
    'label': 'Singapore (新加坡)',
    'iso3': 'SGP',
    'iso2': 'SG',
    'dialCode': '65',
    'exampleNumber': {
      'national': '8123 4567',
      'international': '+65 8123 4567'
    }
  },
  'SH': {
    'label': 'Saint Helena, Ascension and Tristan da Cunha',
    'iso3': 'SHN',
    'iso2': 'SH',
    'dialCode': '290',
    'exampleNumber': {
      'national': '51234',
      'international': '+290 51234'
    }
  },
  'SI': {
    'label': 'Slovenia (Slovenija)',
    'iso3': 'SVN',
    'iso2': 'SI',
    'dialCode': '386',
    'exampleNumber': {
      'national': '031 234 567',
      'international': '+386 31 234 567'
    }
  },
  'SJ': {
    'label': 'Svalbard and Jan Mayen (Svalbard og Jan Mayen)',
    'iso3': 'SJM',
    'iso2': 'SJ',
    'dialCode': '47',
    'exampleNumber': {
      'national': '41 23 45 67',
      'international': '+47 41 23 45 67'
    }
  },
  'SK': {
    'label': 'Slovakia (Slovensko)',
    'iso3': 'SVK',
    'iso2': 'SK',
    'dialCode': '421',
    'exampleNumber': {
      'national': '0912 123 456',
      'international': '+421 912 123 456'
    }
  },
  'SL': {
    'label': 'Sierra Leone',
    'iso3': 'SLE',
    'iso2': 'SL',
    'dialCode': '232',
    'exampleNumber': {
      'national': '(025) 123456',
      'international': '+232 25 123456'
    }
  },
  'SM': {
    'label': 'San Marino',
    'iso3': 'SMR',
    'iso2': 'SM',
    'dialCode': '378',
    'exampleNumber': {
      'national': '66 66 12 12',
      'international': '+378 66 66 12 12'
    }
  },
  'SN': {
    'label': 'Senegal (Sénégal)',
    'iso3': 'SEN',
    'iso2': 'SN',
    'dialCode': '221',
    'exampleNumber': {
      'national': '70 123 45 67',
      'international': '+221 70 123 45 67'
    }
  },
  'SO': {
    'label': 'Somalia (الصومال‎‎)',
    'iso3': 'SOM',
    'iso2': 'SO',
    'dialCode': '252',
    'exampleNumber': {
      'national': '7 1123456',
      'international': '+252 7 1123456'
    }
  },
  'SR': {
    'label': 'Suriname',
    'iso3': 'SUR',
    'iso2': 'SR',
    'dialCode': '597',
    'exampleNumber': {
      'national': '741-2345',
      'international': '+597 741 2345'
    }
  },
  'SS': {
    'label': 'South Sudan',
    'iso3': 'SSD',
    'iso2': 'SS',
    'dialCode': '211',
    'exampleNumber': {
      'national': '0977 123 456',
      'international': '+211 977 123 456'
    }
  },
  'ST': {
    'label': 'São Tomé and Príncipe (São Tomé e Príncipe)',
    'iso3': 'STP',
    'iso2': 'ST',
    'dialCode': '239',
    'exampleNumber': {
      'national': '981 2345',
      'international': '+239 981 2345'
    }
  },
  'SV': {
    'label': 'El Salvador',
    'iso3': 'SLV',
    'iso2': 'SV',
    'dialCode': '503',
    'exampleNumber': {
      'national': '7012 3456',
      'international': '+503 7012 3456'
    }
  },
  'SX': {
    'label': 'Sint Maarten',
    'iso3': 'SXM',
    'iso2': 'SX',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(721) 520-5678',
      'international': '+1 721 520 5678'
    }
  },
  'SY': {
    'label': 'Syria (سوريا)',
    'iso3': 'SYR',
    'iso2': 'SY',
    'dialCode': '963',
    'exampleNumber': {
      'national': '0944 567 890',
      'international': '+963 944 567 890'
    }
  },
  'SZ': {
    'label': 'Eswatini',
    'iso3': 'SWZ',
    'iso2': 'SZ',
    'dialCode': '268',
    'exampleNumber': {
      'national': '7612 3456',
      'international': '+268 7612 3456'
    }
  },
  'TC': {
    'label': 'Turks and Caicos Islands',
    'iso3': 'TCA',
    'iso2': 'TC',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(649) 231-1234',
      'international': '+1 649 231 1234'
    }
  },
  'TD': {
    'label': 'Chad (تشاد‎)',
    'iso3': 'TCD',
    'iso2': 'TD',
    'dialCode': '235',
    'exampleNumber': {
      'national': '63 01 23 45',
      'international': '+235 63 01 23 45'
    }
  },
  'TG': {
    'label': 'Togo',
    'iso3': 'TGO',
    'iso2': 'TG',
    'dialCode': '228',
    'exampleNumber': {
      'national': '90 11 23 45',
      'international': '+228 90 11 23 45'
    }
  },
  'TH': {
    'label': 'Thailand (ประเทศไทย)',
    'iso3': 'THA',
    'iso2': 'TH',
    'dialCode': '66',
    'exampleNumber': {
      'national': '081 234 5678',
      'international': '+66 81 234 5678'
    }
  },
  'TJ': {
    'label': 'Tajikistan (Таджикистан)',
    'iso3': 'TJK',
    'iso2': 'TJ',
    'dialCode': '992',
    'exampleNumber': {
      'national': '91 712 3456',
      'international': '+992 91 712 3456'
    }
  },
  'TK': {
    'label': 'Tokelau',
    'iso3': 'TKL',
    'iso2': 'TK',
    'dialCode': '690',
    'exampleNumber': {
      'national': '7290',
      'international': '+690 7290'
    }
  },
  'TL': {
    'label': 'Timor-Leste',
    'iso3': 'TLS',
    'iso2': 'TL',
    'dialCode': '670',
    'exampleNumber': {
      'national': '7721 2345',
      'international': '+670 7721 2345'
    }
  },
  'TM': {
    'label': 'Turkmenistan (Туркмения)',
    'iso3': 'TKM',
    'iso2': 'TM',
    'dialCode': '993',
    'exampleNumber': {
      'national': '8 66 123456',
      'international': '+993 66 123456'
    }
  },
  'TN': {
    'label': 'Tunisia (تونس)',
    'iso3': 'TUN',
    'iso2': 'TN',
    'dialCode': '216',
    'exampleNumber': {
      'national': '20 123 456',
      'international': '+216 20 123 456'
    }
  },
  'TO': {
    'label': 'Tonga',
    'iso3': 'TON',
    'iso2': 'TO',
    'dialCode': '676',
    'exampleNumber': {
      'national': '771 5123',
      'international': '+676 771 5123'
    }
  },
  'TR': {
    'label': 'Turkey (Türkiye)',
    'iso3': 'TUR',
    'iso2': 'TR',
    'dialCode': '90',
    'exampleNumber': {
      'national': '0501 234 56 78',
      'international': '+90 501 234 56 78'
    }
  },
  'TT': {
    'label': 'Trinidad and Tobago',
    'iso3': 'TTO',
    'iso2': 'TT',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(868) 291-1234',
      'international': '+1 868 291 1234'
    }
  },
  'TV': {
    'label': 'Tuvalu',
    'iso3': 'TUV',
    'iso2': 'TV',
    'dialCode': '688',
    'exampleNumber': {
      'national': '90 1234',
      'international': '+688 90 1234'
    }
  },
  'TW': {
    'label': 'Taiwan (台灣)',
    'iso3': 'TWN',
    'iso2': 'TW',
    'dialCode': '886',
    'exampleNumber': {
      'national': '0912 345 678',
      'international': '+886 912 345 678'
    }
  },
  'TZ': {
    'label': 'Tanzania',
    'iso3': 'TZA',
    'iso2': 'TZ',
    'dialCode': '255',
    'exampleNumber': {
      'national': '0621 234 567',
      'international': '+255 621 234 567'
    }
  },
  'UA': {
    'label': 'Ukraine (Україна)',
    'iso3': 'UKR',
    'iso2': 'UA',
    'dialCode': '380',
    'exampleNumber': {
      'national': '050 123 4567',
      'international': '+380 50 123 4567'
    }
  },
  'UG': {
    'label': 'Uganda',
    'iso3': 'UGA',
    'iso2': 'UG',
    'dialCode': '256',
    'exampleNumber': {
      'national': '0712 345678',
      'international': '+256 712 345678'
    }
  },
  'US': {
    'label': 'United States',
    'iso3': 'USA',
    'iso2': 'US',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(201) 555-0123',
      'international': '+1 201 555 0123'
    }
  },
  'UY': {
    'label': 'Uruguay',
    'iso3': 'URY',
    'iso2': 'UY',
    'dialCode': '598',
    'exampleNumber': {
      'national': '094 231 234',
      'international': '+598 94 231 234'
    }
  },
  'UZ': {
    'label': 'Uzbekistan (Узбекистан)',
    'iso3': 'UZB',
    'iso2': 'UZ',
    'dialCode': '998',
    'exampleNumber': {
      'national': '8 91 234 56 78',
      'international': '+998 91 234 56 78'
    }
  },
  'VA': {
    'label': 'Vatican City (Vaticano)',
    'iso3': 'VAT',
    'iso2': 'VA',
    'dialCode': '39',
    'exampleNumber': {
      'national': '312 345 6789',
      'international': '+39 312 345 6789'
    }
  },
  'VC': {
    'label': 'Saint Vincent and the Grenadines',
    'iso3': 'VCT',
    'iso2': 'VC',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(784) 430-1234',
      'international': '+1 784 430 1234'
    }
  },
  'VE': {
    'label': 'Venezuela',
    'iso3': 'VEN',
    'iso2': 'VE',
    'dialCode': '58',
    'exampleNumber': {
      'national': '0412-1234567',
      'international': '+58 412 1234567'
    }
  },
  'VG': {
    'label': 'British Virgin Islands',
    'iso3': 'VGB',
    'iso2': 'VG',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(284) 300-1234',
      'international': '+1 284 300 1234'
    }
  },
  'VI': {
    'label': 'United States Virgin Islands',
    'iso3': 'VIR',
    'iso2': 'VI',
    'dialCode': '1',
    'exampleNumber': {
      'national': '(340) 642-1234',
      'international': '+1 340 642 1234'
    }
  },
  'VN': {
    'label': 'Vietnam (Việt Nam)',
    'iso3': 'VNM',
    'iso2': 'VN',
    'dialCode': '84',
    'exampleNumber': {
      'national': '0912 345 678',
      'international': '+84 912 345 678'
    }
  },
  'VU': {
    'label': 'Vanuatu',
    'iso3': 'VUT',
    'iso2': 'VU',
    'dialCode': '678',
    'exampleNumber': {
      'national': '591 2345',
      'international': '+678 591 2345'
    }
  },
  'WF': {
    'label': 'Wallis and Futuna (Wallis et Futuna)',
    'iso3': 'WLF',
    'iso2': 'WF',
    'dialCode': '681',
    'exampleNumber': {
      'national': '82 12 34',
      'international': '+681 82 12 34'
    }
  },
  'WS': {
    'label': 'Samoa',
    'iso3': 'WSM',
    'iso2': 'WS',
    'dialCode': '685',
    'exampleNumber': {
      'national': '72 12345',
      'international': '+685 72 12345'
    }
  },
  'XK': {
    'label': 'Kosovo (Kosova)',
    'iso3': 'UNK',
    'iso2': 'XK',
    'dialCode': '383',
    'exampleNumber': {
      'national': '043 201 234',
      'international': '+383 43 201 234'
    }
  },
  'YE': {
    'label': 'Yemen (اليَمَن)',
    'iso3': 'YEM',
    'iso2': 'YE',
    'dialCode': '967',
    'exampleNumber': {
      'national': '0712 345 678',
      'international': '+967 712 345 678'
    }
  },
  'YT': {
    'label': 'Mayotte',
    'iso3': 'MYT',
    'iso2': 'YT',
    'dialCode': '262',
    'exampleNumber': {
      'national': '0639 01 23 45',
      'international': '+262 639 01 23 45'
    }
  },
  'ZA': {
    'label': 'South Africa',
    'iso3': 'ZAF',
    'iso2': 'ZA',
    'dialCode': '27',
    'exampleNumber': {
      'national': '071 123 4567',
      'international': '+27 71 123 4567'
    }
  },
  'ZM': {
    'label': 'Zambia',
    'iso3': 'ZMB',
    'iso2': 'ZM',
    'dialCode': '260',
    'exampleNumber': {
      'national': '095 5123456',
      'international': '+260 95 5123456'
    }
  },
  'ZW': {
    'label': 'Zimbabwe',
    'iso3': 'ZWE',
    'iso2': 'ZW',
    'dialCode': '263',
    'exampleNumber': {
      'national': '071 234 5678',
      'international': '+263 71 234 5678'
    }
  }
}

const isoCountriesEntries = Object.entries(isoCountries).map(([lang, { label, dialCode, iso2 }]) => ({ lang, label, dialCode, iso2 }))

module.exports = { isoCountries, isoCountriesEntries }
